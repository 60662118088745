import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Sun = () => {
  const classes = useStyles();

  return (
    <div className={classes.sun}>
      <div className={classes.rayBox}>
        <div className={`${classes.ray} ${classes.ray1}`} />
        <div className={`${classes.ray} ${classes.ray2}`} />
        <div className={`${classes.ray} ${classes.ray3}`} />
        <div className={`${classes.ray} ${classes.ray4}`} />
        <div className={`${classes.ray} ${classes.ray5}`} />
        <div className={`${classes.ray} ${classes.ray6}`} />
        <div className={`${classes.ray} ${classes.ray7}`} />
        <div className={`${classes.ray} ${classes.ray8}`} />
        <div className={`${classes.ray} ${classes.ray9}`} />
        <div className={`${classes.ray} ${classes.ray10}`} />
      </div>
    </div>
  );
};

export default Sun;
