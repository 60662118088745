export default {
  '@keyframes moveclouds': {
    '0%': { marginLeft: '-200px' },
    '100%': { marginLeft: 'calc(100% + 200px)' },
  },
  cloud: {
    background: '#fff',
    borderRadius: '200px',
    height: '60px',
    position: 'relative',
    width: '200px',
    '&:before, &:after': {
      background: '#fff',
      borderRadius: '100px',
      content: '""',
      height: '80px',
      left: '10px',
      position: 'absolute',
      top: '-15px',
      transform: 'rotate(30deg)',
      width: '100px',
    },
    '&:after': {
      height: '120px',
      left: 'auto',
      right: '15px',
      top: '-55px',
      width: '120px',
    },
  },
  clouds: {
    height: '100%',
    overflow: 'hidden',
    padding: '100px 0',
    position: 'absolute',
    width: '100%',
    zIndex: '-2',
  },
  x1: {
    animation: '$moveclouds 15s linear infinite',
  },
  x2: {
    animation: '$moveclouds 25s linear infinite',
    left: '200px',
    opacity: 0.6,
    transform: 'scale(0.6)',
  },
  x3: {
    animation: '$moveclouds 20s linear infinite',
    left: '-250px',
    opacity: 0.8,
    top: '-200px',
    transform: 'scale(0.8)',
  },
  x4: {
    animation: '$moveclouds 18s linear infinite',
    left: '470px',
    opacity: 0.75,
    top: '-250px',
    transform: 'scale(0.75)',
  },
  x5: {
    animation: '$moveclouds 20s linear infinite',
    left: '-150px',
    opacity: 0.8,
    top: '-150px',
    transform: 'scale(0.8)',
  },
};
