import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';
import Firework from './Firework/Firework';
import Sky from './Sky/Sky';

const useStyles = makeStyles(styles);

const Summer = () => {
  const classes = useStyles();

  return (
    <>
      <Sky />
      <Firework />
      <div className={classes.vanillaVince} />
      <div className={classes.alecAcorn} />
      <div className={classes.sammySeal} />
      <div className={classes.willie} />
      <div className={classes.beachChair} />
      <div className={classes.sunglasses} />
    </>
  );
};

export default Summer;
