import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Sun from './Sun/Sun';
import Clouds from './Clouds/Clouds';
import styles from './styles';

const useStyles = makeStyles(styles);

const Sky = () => {
  const classes = useStyles();

  return (
    <div className={classes.sky}>
      <Sun />
      <Clouds />
    </div>
  );
};

export default Sky;
