import { options, handleResponse } from 'lib/homeChefApi';
import { API_BASE_URI, ASSEMBLY } from 'lib/constants';
import { buildArrayUrlParameter, snakeCasedParams } from 'lib/utils';

export const getActivityIngredients = async ({ menuId, activity }) => {
  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/ingredient_progress?menu_id=${menuId}&activity=${activity}`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};

export const getActivityIngredientProgresses = async ({
  activity,
  ingredientId,
  mealIds,
}) => {
  const mealIdsQueryString = buildArrayUrlParameter('meal_ids', mealIds);

  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/ingredient_progress/${ingredientId}?activity=${activity}&${mealIdsQueryString}`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};

export const getMealProgresses = async (menuId) => {
  const endpoint =
    API_BASE_URI +
    `/ops/production_progress/rth/meal_progress?menu_id=${menuId}`;
  const response = await fetch(endpoint, { ...options, method: 'GET' });

  return await handleResponse(response);
};

export const postMealProgress = async (postParams) => {
  const { progressId, ...activityParams } = postParams;

  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/meal_progress`;
  const response = await fetch(endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      ...snakeCasedParams(activityParams),
      id: progressId,
    }),
  });

  return await handleResponse(response);
};

export const putMealProgress = async (updateParams) => {
  const { progressId, ...activityParams } = updateParams;

  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/meal_progress/${progressId}`;
  const response = await fetch(endpoint, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({ ...snakeCasedParams(activityParams) }),
  });

  return await handleResponse(response);
};

export const postIngredientProgress = async (postParams) => {
  const { progressId, ...activityParams } = postParams;

  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/ingredient_progress`;

  const response = await fetch(endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      ...snakeCasedParams(activityParams),
      id: progressId,
    }),
  });

  return await handleResponse(response);
};

export const putIngredientProgress = async (updateParams) => {
  const { progressId, ...activityParams } = updateParams;

  const endpoint = `${API_BASE_URI}/ops/production_progress/rth/ingredient_progress/${progressId}`;

  const response = await fetch(endpoint, {
    ...options,
    method: 'PUT',
    body: JSON.stringify({ ...snakeCasedParams(activityParams) }),
  });

  return await handleResponse(response);
};

export const printRthLabels = async (printParams) => {
  const { activity, progressId, labelCount, printerId, sublotId } = printParams;
  const endpoint =
    activity === ASSEMBLY
      ? `${API_BASE_URI}/ops/production_progress/rth/meal_progress/${progressId}/print_labels`
      : `${API_BASE_URI}/ops/production_progress/rth/ingredient_progress/${progressId}/print_labels`;

  const response = await fetch(endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify({
      activity: activity,
      label_count: labelCount,
      printer_id: printerId,
      sublot_id: sublotId,
    }),
  });

  return await handleResponse(response);
};
