export const apiRequestConstant = (type) => {
  return `${type}_REQUEST`;
};
export const apiSuccessConstant = (type) => {
  return `${type}_SUCCESS`;
};
export const apiErrorConstant = (type) => {
  return `${type}_ERROR`;
};

export const DOCK_APPOINTMENT_POST = 'DOCK_APPOINTMENT_POST';
export const DOCK_APPOINTMENT_POST_REQUEST = apiRequestConstant(
  DOCK_APPOINTMENT_POST
);
export const DOCK_APPOINTMENT_POST_SUCCESS = apiSuccessConstant(
  DOCK_APPOINTMENT_POST
);
export const DOCK_APPOINTMENT_POST_ERROR = apiErrorConstant(
  DOCK_APPOINTMENT_POST
);

export const DOCK_APPOINTMENT_PUT = 'DOCK_APPOINTMENT_PUT';
export const DOCK_APPOINTMENT_PUT_REQUEST = apiRequestConstant(
  DOCK_APPOINTMENT_PUT
);
export const DOCK_APPOINTMENT_PUT_SUCCESS = apiSuccessConstant(
  DOCK_APPOINTMENT_PUT
);
export const DOCK_APPOINTMENT_PUT_ERROR = apiErrorConstant(
  DOCK_APPOINTMENT_PUT
);

export const DOCK_APPOINTMENT_DELETE = 'DOCK_APPOINTMENT_DELETE';
export const DOCK_APPOINTMENT_DELETE_REQUEST = apiRequestConstant(
  DOCK_APPOINTMENT_DELETE
);
export const DOCK_APPOINTMENT_DELETE_SUCCESS = apiSuccessConstant(
  DOCK_APPOINTMENT_DELETE
);
export const DOCK_APPOINTMENT_DELETE_ERROR = apiErrorConstant(
  DOCK_APPOINTMENT_DELETE
);

export const DOCK_APPOINTMENTS = 'DOCK_APPOINTMENTS';
export const DOCK_APPOINTMENTS_REQUEST = apiRequestConstant(
  'DOCK_APPOINTMENTS'
);
export const DOCK_APPOINTMENTS_SUCCESS = apiSuccessConstant(
  'DOCK_APPOINTMENTS'
);
export const DOCK_APPOINTMENTS_ERROR = apiErrorConstant('DOCK_APPOINTMENTS');

export const DOCK_DOORS = 'DOCK_DOORS';
export const DOCK_DOORS_REQUEST = apiRequestConstant(DOCK_DOORS);
export const DOCK_DOORS_SUCCESS = apiSuccessConstant(DOCK_DOORS);

export const DROP_LIST_POST = 'DROP_LIST_POST';
export const DROP_LIST_POST_REQUEST = apiRequestConstant(DROP_LIST_POST);
export const DROP_LIST_POST_SUCCESS = apiSuccessConstant(DROP_LIST_POST);
export const DROP_LIST_POST_ERROR = apiErrorConstant(DROP_LIST_POST);

export const LOGIN_FIELDS = 'LOGIN_FIELDS';
export const LOGIN_FIELDS_REQUEST = apiRequestConstant('LOGIN_FIELDS');
export const LOGIN_FIELDS_SUCCESS = apiSuccessConstant('LOGIN_FIELDS');

export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_REQUEST = apiRequestConstant('SEARCH_USERS');
export const SEARCH_USERS_SUCCESS = apiSuccessConstant('SEARCH_USERS');
export const SEARCH_USERS_ERROR = apiErrorConstant('SEARCH_USERS');
export const CLEAR_SEARCH_USERS = 'CLEAR_SEARCH_USERS';

export const NETSUITE_ORDER_ITEMS = 'NETSUITE_ORDER_ITEMS';
export const NETSUITE_ORDER_ITEMS_REQUEST = apiRequestConstant(
  NETSUITE_ORDER_ITEMS
);
export const NETSUITE_ORDER_ITEMS_SUCCESS = apiSuccessConstant(
  NETSUITE_ORDER_ITEMS
);
export const NETSUITE_ORDER_ITEM_CLEAR_SEARCH =
  'NETSUITE_ORDER_ITEM_CLEAR_SEARCH';
export const NETSUITE_ORDER_ITEM_STORE_SEARCH =
  'NETSUITE_ORDER_ITEM_STORE_SEARCH';

export const INVENTORY_PALLET_QUERY = 'INVENTORY_PALLET_QUERY';
export const INVENTORY_PALLET_QUERY_GET_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_QUERY
);
export const INVENTORY_PALLET_QUERY_GET_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_QUERY
);
export const INVENTORY_PALLET_QUERY_GET_ERROR = apiErrorConstant(
  INVENTORY_PALLET_QUERY
);

export const INVENTORY_PALLETS_TOGGLE_SEARCH =
  'INVENTORY_PALLETS_TOGGLE_SEARCH';
export const INVENTORY_PALLETS_CLEAR_SEARCH = 'INVENTORY_PALLETS_CLEAR_SEARCH';
export const INVENTORY_PALLETS_STORE_SEARCH = 'INVENTORY_PALLETS_STORE_SEARCH';

export const RECEIVING_PALLETS_POST = 'RECEIVING_PALLETS_POST';
export const RECEIVING_PALLETS_POST_REQUEST = apiRequestConstant(
  RECEIVING_PALLETS_POST
);
export const RECEIVING_PALLETS_POST_SUCCESS = apiSuccessConstant(
  RECEIVING_PALLETS_POST
);
export const RECEIVING_PALLETS_POST_ERROR = apiErrorConstant(
  RECEIVING_PALLETS_POST
);

export const AUDIT_ORDERS_GET = 'AUDIT_ORDERS_GET';
export const AUDIT_ORDERS_GET_REQUEST = apiRequestConstant(AUDIT_ORDERS_GET);
export const AUDIT_ORDERS_GET_SUCCESS = apiSuccessConstant(AUDIT_ORDERS_GET);
export const AUDIT_ORDERS_GET_ERROR = apiErrorConstant(AUDIT_ORDERS_GET);

export const AUDIT_TRUCKS_GET = 'AUDIT_TRUCKS_GET';
export const AUDIT_TRUCKS_GET_REQUEST = apiRequestConstant(AUDIT_TRUCKS_GET);
export const AUDIT_TRUCKS_GET_SUCCESS = apiSuccessConstant(AUDIT_TRUCKS_GET);
export const AUDIT_TRUCKS_GET_ERROR = apiErrorConstant(AUDIT_TRUCKS_GET);

export const AUDIT_ITEM_UPDATE = 'AUDIT_ITEM_UPDATE';
export const AUDIT_ITEM_UPDATE_REQUEST = apiRequestConstant(AUDIT_ITEM_UPDATE);
export const AUDIT_ITEM_UPDATE_SUCCESS = apiSuccessConstant(AUDIT_ITEM_UPDATE);
export const AUDIT_ITEM_UPDATE_ERROR = apiErrorConstant(AUDIT_ITEM_UPDATE);

export const SUMMARY_ORDERS_GET = 'SUMMARY_ORDERS_GET';
export const SUMMARY_ORDERS_GET_REQUEST = apiRequestConstant(
  SUMMARY_ORDERS_GET
);
export const SUMMARY_ORDERS_GET_SUCCESS = apiSuccessConstant(
  SUMMARY_ORDERS_GET
);
export const SUMMARY_ORDERS_GET_ERROR = apiErrorConstant(SUMMARY_ORDERS_GET);

export const SUMMARY_AUDIT_ORDERS_TRUCKS_GET =
  'SUMMARY_AUDIT_ORDERS_TRUCKS_GET';
export const SUMMARY_AUDIT_ORDERS_TRUCKS_GET_REQUEST = apiRequestConstant(
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET
);
export const SUMMARY_AUDIT_ORDERS_TRUCKS_GET_SUCCESS = apiSuccessConstant(
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET
);
export const SUMMARY_AUDIT_ORDERS_TRUCKS_GET_ERROR = apiErrorConstant(
  SUMMARY_AUDIT_ORDERS_TRUCKS_GET
);

export const SUMMARY_ITEM_UPDATE = 'SUMMARY_ITEM_UPDATE';
export const SUMMARY_ITEM_UPDATE_REQUEST = apiRequestConstant(
  SUMMARY_ITEM_UPDATE
);
export const SUMMARY_ITEM_UPDATE_SUCCESS = apiSuccessConstant(
  SUMMARY_ITEM_UPDATE
);
export const SUMMARY_ITEM_UPDATE_ERROR = apiErrorConstant(SUMMARY_ITEM_UPDATE);

export const PALLETS_PRINT = 'PALLETS_PRINT';
export const PALLETS_PRINT_REQUEST = apiRequestConstant(PALLETS_PRINT);
export const PALLETS_PRINT_SUCCESS = apiSuccessConstant(PALLETS_PRINT);
export const PALLETS_PRINT_ERROR = apiErrorConstant(PALLETS_PRINT);

export const RECEIVING_PALLETS_GET = 'RECEIVING_PALLETS_GET';
export const RECEIVING_PALLETS_GET_REQUEST = apiRequestConstant(
  RECEIVING_PALLETS_GET
);
export const RECEIVING_PALLETS_GET_SUCCESS = apiSuccessConstant(
  RECEIVING_PALLETS_GET
);
export const RECEIVING_PALLETS_GET_ERROR = apiErrorConstant(
  RECEIVING_PALLETS_GET
);

export const INVENTORY_PALLET_DEACTIVATE = 'INVENTORY_PALLET_DEACTIVATE';
export const INVENTORY_PALLET_DEACTIVATE_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_DEACTIVATE
);
export const INVENTORY_PALLET_DEACTIVATE_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_DEACTIVATE
);
export const INVENTORY_PALLET_DEACTIVATE_ERROR = apiErrorConstant(
  INVENTORY_PALLET_DEACTIVATE
);

export const INVENTORY_PALLET_HOLD = 'INVENTORY_PALLET_HOLD';
export const INVENTORY_PALLET_HOLD_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_HOLD
);
export const INVENTORY_PALLET_HOLD_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_HOLD
);
export const INVENTORY_PALLET_HOLD_ERROR = apiErrorConstant(
  INVENTORY_PALLET_HOLD
);

export const INVENTORY_PALLET_REACTIVATE = 'INVENTORY_PALLET_REACTIVATE';
export const INVENTORY_PALLET_REACTIVATE_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_REACTIVATE
);
export const INVENTORY_PALLET_REACTIVATE_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_REACTIVATE
);
export const INVENTORY_PALLET_REACTIVATE_ERROR = apiErrorConstant(
  INVENTORY_PALLET_REACTIVATE
);

export const RECEIVING_PALLETS_RESET = 'RECEIVING_PALLETS_RESET';

export const INVENTORY_PALLET_GET = 'INVENTORY_PALLET_GET';
export const INVENTORY_PALLET_GET_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_GET
);
export const INVENTORY_PALLET_GET_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_GET
);
export const INVENTORY_PALLET_GET_ERROR = apiErrorConstant(
  INVENTORY_PALLET_GET
);

export const WMS_ITEM_RECEIPT_CREATE = 'WMS_ITEM_RECEIPT_CREATE';
export const WMS_ITEM_RECEIPT_CREATE_REQUEST = apiRequestConstant(
  WMS_ITEM_RECEIPT_CREATE
);
export const WMS_ITEM_RECEIPT_CREATE_SUCCESS = apiSuccessConstant(
  WMS_ITEM_RECEIPT_CREATE
);
export const WMS_ITEM_RECEIPT_CREATE_ERROR = apiErrorConstant(
  WMS_ITEM_RECEIPT_CREATE
);

export const WMS_ITEM_RECEIPT_UPDATE = 'WMS_ITEM_RECEIPT_UPDATE';
export const WMS_ITEM_RECEIPT_UPDATE_REQUEST = apiRequestConstant(
  WMS_ITEM_RECEIPT_UPDATE
);
export const WMS_ITEM_RECEIPT_UPDATE_SUCCESS = apiSuccessConstant(
  WMS_ITEM_RECEIPT_UPDATE
);
export const WMS_ITEM_RECEIPT_UPDATE_ERROR = apiErrorConstant(
  WMS_ITEM_RECEIPT_UPDATE
);

export const INVENTORY_PALLET_PUT = 'INVENTORY_PALLET_PUT';
export const INVENTORY_PALLET_PUT_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_PUT
);
export const INVENTORY_PALLET_PUT_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_PUT
);
export const INVENTORY_PALLET_PUT_ERROR = apiErrorConstant(
  INVENTORY_PALLET_PUT
);

export const INVENTORY_PALLET_CYCLE_COUNT = 'INVENTORY_PALLET_CYCLE_COUNT';
export const INVENTORY_PALLET_CYCLE_COUNT_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_CYCLE_COUNT
);
export const INVENTORY_PALLET_CYCLE_COUNT_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_CYCLE_COUNT
);
export const INVENTORY_PALLET_CYCLE_COUNT_ERROR = apiErrorConstant(
  INVENTORY_PALLET_CYCLE_COUNT
);

export const UPDATE_COUNT_LIST_ASSIGNMENT = 'UPDATE_COUNT_LIST_ASSIGNMENT';
export const UPDATE_COUNT_LIST_ASSIGNMENT_REQUEST = apiRequestConstant(
  UPDATE_COUNT_LIST_ASSIGNMENT
);
export const UPDATE_COUNT_LIST_ASSIGNMENT_SUCCESS = apiSuccessConstant(
  UPDATE_COUNT_LIST_ASSIGNMENT
);
export const UPDATE_COUNT_LIST_ASSIGNMENT_ERROR = apiErrorConstant(
  UPDATE_COUNT_LIST_ASSIGNMENT
);

export const INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION =
  'INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION';
export const INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION
);
export const INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION
);
export const INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION_ERROR = apiErrorConstant(
  INVENTORY_PALLET_CYCLE_COUNT_DEACTIVATION
);

export const INVENTORY_PALLET_UNKNOWN_LOCATION =
  'INVENTORY_PALLET_UNKNOWN_LOCATION';
export const INVENTORY_PALLET_UNKNOWN_LOCATION_REQUEST = apiRequestConstant(
  INVENTORY_PALLET_UNKNOWN_LOCATION
);
export const INVENTORY_PALLET_UNKNOWN_LOCATION_SUCCESS = apiSuccessConstant(
  INVENTORY_PALLET_UNKNOWN_LOCATION
);
export const INVENTORY_PALLET_UNKNOWN_LOCATION_ERROR = apiErrorConstant(
  INVENTORY_PALLET_UNKNOWN_LOCATION
);

export const INVENTORY_PALLET_RESET_SELECTED =
  'INVENTORY_PALLET_RESET_SELECTED';
export const RECEIVING_PALLET_DELETE = 'RECEIVING_PALLET_DELETE';
export const RECEIVING_PALLET_DELETE_REQUEST = apiRequestConstant(
  RECEIVING_PALLET_DELETE
);
export const RECEIVING_PALLET_DELETE_SUCCESS = apiSuccessConstant(
  RECEIVING_PALLET_DELETE
);
export const RECEIVING_PALLET_DELETE_ERROR = apiErrorConstant(
  RECEIVING_PALLET_DELETE
);

export const FSQA_PALLETS_GET = 'FSQA_PALLETS_GET';
export const FSQA_PALLETS_GET_REQUEST = apiRequestConstant(FSQA_PALLETS_GET);
export const FSQA_PALLETS_GET_SUCCESS = apiSuccessConstant(FSQA_PALLETS_GET);
export const FSQA_PALLETS_GET_ERROR = apiErrorConstant(FSQA_PALLETS_GET);

export const FSQA_PHOTO_POST = 'FSQA_PHOTO_POST';
export const FSQA_PHOTO_POST_REQUEST = apiRequestConstant(FSQA_PHOTO_POST);
export const FSQA_PHOTO_POST_SUCCESS = apiSuccessConstant(FSQA_PHOTO_POST);
export const FSQA_PHOTO_POST_ERROR = apiErrorConstant(FSQA_PHOTO_POST);

export const FSQA_PHOTO_DELETE = 'FSQA_PHOTO_DELETE';
export const FSQA_PHOTO_DELETE_REQUEST = apiRequestConstant(FSQA_PHOTO_DELETE);
export const FSQA_PHOTO_DELETE_SUCCESS = apiSuccessConstant(FSQA_PHOTO_DELETE);
export const FSQA_PHOTO_DELETE_ERROR = apiErrorConstant(FSQA_PHOTO_DELETE);

export const FSQA_PHOTOS_GET = 'FSQA_PHOTOS_GET';
export const FSQA_PHOTOS_GET_REQUEST = apiRequestConstant(FSQA_PHOTOS_GET);
export const FSQA_PHOTOS_GET_SUCCESS = apiSuccessConstant(FSQA_PHOTOS_GET);
export const FSQA_PHOTOS_GET_ERROR = apiErrorConstant(FSQA_PHOTOS_GET);

export const FSQA_REVIEW_POST = 'FSQA_REVIEW_POST';
export const FSQA_REVIEW_POST_REQUEST = apiRequestConstant(FSQA_REVIEW_POST);
export const FSQA_REVIEW_POST_SUCCESS = apiSuccessConstant(FSQA_REVIEW_POST);
export const FSQA_REVIEW_POST_ERROR = apiErrorConstant(FSQA_REVIEW_POST);

export const FSQA_REVIEW_PUT = 'FSQA_REVIEW_PUT';
export const FSQA_REVIEW_PUT_REQUEST = apiRequestConstant(FSQA_REVIEW_PUT);
export const FSQA_REVIEW_PUT_SUCCESS = apiSuccessConstant(FSQA_REVIEW_PUT);
export const FSQA_REVIEW_PUT_ERROR = apiErrorConstant(FSQA_REVIEW_PUT);

export const FSQA_REVIEW_GET = 'FSQA_REVIEW_GET';
export const FSQA_REVIEW_GET_REQUEST = apiRequestConstant(FSQA_REVIEW_GET);
export const FSQA_REVIEW_GET_SUCCESS = apiSuccessConstant(FSQA_REVIEW_GET);
export const FSQA_REVIEW_GET_ERROR = apiErrorConstant(FSQA_REVIEW_GET);

export const FSQA_SEARCH_PALLET = 'FSQA_SEARCH_PALLET';
export const FSQA_RESET_SEARCH_PALLET = 'FSQA_RESET_SEARCH_PALLET';
export const FSQA_ADD_TO_FORM = 'FSQA_ADD_TO_FORM';
export const FSQA_ADD_PALLETS = 'FSQA_ADD_PALLETS';
export const FSQA_CLEAR_FORM = 'FSQA_CLEAR_FORM';
export const FSQA_TOGGLE_FULL_CHECK = 'FSQA_TOGGLE_FULL_CHECK';
export const FSQA_ADD_ALLERGEN_TO_FORM = 'FSQA_ADD_ALLERGEN_TO_FORM';

export const PALLET_HISTORIES_GET = 'PALLET_HISTORIES_GET';
export const PALLET_HISTORIES_GET_REQUEST = apiRequestConstant(
  PALLET_HISTORIES_GET
);
export const PALLET_HISTORIES_GET_SUCCESS = apiSuccessConstant(
  PALLET_HISTORIES_GET
);
export const PALLET_HISTORIES_GET_ERROR = apiErrorConstant(
  PALLET_HISTORIES_GET
);
export const PALLET_HISTORIES_RESET = 'PALLET_HISTORIES_RESET';

export const STORAGE_AREAS = 'STORAGE_AREAS';
export const STORAGE_AREAS_REQUEST = apiRequestConstant(STORAGE_AREAS);
export const STORAGE_AREAS_SUCCESS = apiSuccessConstant(STORAGE_AREAS);

export const ITEM_PUTBACK = 'ITEM_PUTBACK';
export const ITEM_PUTBACK_REQUEST = apiRequestConstant(ITEM_PUTBACK);
export const ITEM_PUTBACK_ERROR = apiErrorConstant(ITEM_PUTBACK);
export const ITEM_PUTBACK_SUCCESS = apiSuccessConstant(ITEM_PUTBACK);

export const ITEM_PUTBACKS = 'ITEM_PUTBACKS';
export const ITEM_PUTBACKS_REQUEST = apiRequestConstant(ITEM_PUTBACKS);
export const ITEM_PUTBACKS_ERROR = apiErrorConstant(ITEM_PUTBACKS);
export const ITEM_PUTBACKS_SUCCESS = apiSuccessConstant(ITEM_PUTBACKS);

export const RESET_PARENT_PALLET = 'RESET_PARENT_PALLET';

export const ITEM_PUTBACK_PARENT_PALLET = 'ITEM_PUTBACK_PARENT_PALLET';
export const ITEM_PUTBACK_PARENT_PALLET_REQUEST = apiRequestConstant(
  ITEM_PUTBACK_PARENT_PALLET
);
export const ITEM_PUTBACK_PARENT_PALLET_REQUEST_ERROR = apiErrorConstant(
  ITEM_PUTBACK_PARENT_PALLET
);
export const ITEM_PUTBACK_PARENT_PALLET_REQUEST_SUCCESS = apiSuccessConstant(
  ITEM_PUTBACK_PARENT_PALLET
);

export const ITEM_PUTBACK_EXCEPTION_CREATE = 'ITEM_PUTBACK_EXCEPTION_CREATE';
export const ITEM_PUTBACK_EXCEPTION_CREATE_REQUEST = apiRequestConstant(
  ITEM_PUTBACK_EXCEPTION_CREATE
);
export const ITEM_PUTBACK_EXCEPTION_CREATE_ERROR = apiErrorConstant(
  ITEM_PUTBACK_EXCEPTION_CREATE
);
export const ITEM_PUTBACK_EXCEPTION_CREATE_SUCCESS = apiSuccessConstant(
  ITEM_PUTBACK_EXCEPTION_CREATE
);

export const ITEM_PUTBACK_CREATE = 'ITEM_PUTBACK_CREATE';
export const ITEM_PUTBACK_CREATE_REQUEST = apiRequestConstant(
  ITEM_PUTBACK_CREATE
);
export const ITEM_PUTBACK_CREATE_ERROR = apiErrorConstant(ITEM_PUTBACK_CREATE);
export const ITEM_PUTBACK_CREATE_SUCCESS = apiSuccessConstant(
  ITEM_PUTBACK_CREATE
);

export const ITEM_PUTBACK_CANCEL = 'ITEM_PUTBACK_CANCEL';
export const ITEM_PUTBACK_CANCEL_REQUEST = apiRequestConstant(
  ITEM_PUTBACK_CANCEL
);
export const ITEM_PUTBACK_CANCEL_ERROR = apiErrorConstant(ITEM_PUTBACK_CANCEL);
export const ITEM_PUTBACK_CANCEL_SUCCESS = apiSuccessConstant(
  ITEM_PUTBACK_CANCEL
);

export const ITEM_PUTBACK_COMPLETE = 'ITEM_PUTBACK_COMPLETE';
export const ITEM_PUTBACK_COMPLETE_REQUEST = apiRequestConstant(
  ITEM_PUTBACK_COMPLETE
);
export const ITEM_PUTBACK_COMPLETE_ERROR = apiErrorConstant(
  ITEM_PUTBACK_COMPLETE
);
export const ITEM_PUTBACK_COMPLETE_SUCCESS = apiSuccessConstant(
  ITEM_PUTBACK_COMPLETE
);

export const COUNT_LIST = 'COUNT_LIST';
export const COUNT_LIST_REQUEST = apiRequestConstant(COUNT_LIST);
export const COUNT_LIST_SUCCESS = apiSuccessConstant(COUNT_LIST);
export const COUNT_LIST_ERROR = apiErrorConstant(COUNT_LIST);

export const COUNT_LISTS = 'COUNT_LISTS';
export const COUNT_LISTS_REQUEST = apiRequestConstant(COUNT_LISTS);
export const COUNT_LISTS_SUCCESS = apiSuccessConstant(COUNT_LISTS);
export const COUNT_LISTS_ERROR = apiErrorConstant(COUNT_LISTS);

export const STORAGE_SLOTS = 'STORAGE_SLOTS';
export const STORAGE_SLOTS_REQUEST = apiRequestConstant(STORAGE_SLOTS);
export const STORAGE_SLOTS_SUCCESS = apiSuccessConstant(STORAGE_SLOTS);
export const STORAGE_SLOTS_ERROR = apiErrorConstant(STORAGE_SLOTS);

export const RESET_MISSING_SLOT_PALLETS = 'RESET_MISSING_SLOT_PALLETS';
export const MISSING_SLOT_PALLETS = 'MISSING_SLOT_PALLETS';
export const MISSING_SLOT_PALLETS_REQUEST = apiRequestConstant(
  MISSING_SLOT_PALLETS
);
export const MISSING_SLOT_PALLETS_SUCCESS = apiSuccessConstant(
  MISSING_SLOT_PALLETS
);
export const MISSING_SLOT_PALLETS_ERROR = apiErrorConstant(
  MISSING_SLOT_PALLETS
);

export const RESET_STORAGE_SLOTS = 'RESET_STORAGE_SLOTS';

export const STORAGE_SLOT_DEPTHS = 'STORAGE_SLOT_DEPTHS';
export const STORAGE_SLOT_DEPTHS_REQUEST = apiRequestConstant(
  STORAGE_SLOT_DEPTHS
);
export const STORAGE_SLOT_DEPTHS_SUCCESS = apiSuccessConstant(
  STORAGE_SLOT_DEPTHS
);
export const STORAGE_SLOT_DEPTHS_ERROR = apiErrorConstant(STORAGE_SLOT_DEPTHS);

export const MEAL_BAG_INVENTORY_POST = 'MEAL_BAG_INVENTORY_POST';
export const MEAL_BAG_INVENTORY_POST_REQUEST = apiRequestConstant(
  MEAL_BAG_INVENTORY_POST
);
export const MEAL_BAG_INVENTORY_POST_SUCCESS = apiSuccessConstant(
  MEAL_BAG_INVENTORY_POST
);
export const MEAL_BAG_INVENTORY_POST_ERROR = apiErrorConstant(
  MEAL_BAG_INVENTORY_POST
);

export const MEAL_BAG_INVENTORY_PRINT = 'MEAL_BAG_INVENTORY_PRINT';
export const MEAL_BAG_INVENTORY_PRINT_REQUEST = apiRequestConstant(
  MEAL_BAG_INVENTORY_PRINT
);
export const MEAL_BAG_INVENTORY_PRINT_SUCCESS = apiSuccessConstant(
  MEAL_BAG_INVENTORY_PRINT
);
export const MEAL_BAG_INVENTORY_PRINT_ERROR = apiErrorConstant(
  MEAL_BAG_INVENTORY_PRINT
);

export const MEAL_BAG_INGREDIENT_ASSIGNMENTS =
  'MEAL_BAG_INGREDIENT_ASSIGNMENTS';
export const MEAL_BAG_INGREDIENT_ASSIGNMENTS_REQUEST = apiRequestConstant(
  MEAL_BAG_INGREDIENT_ASSIGNMENTS
);
export const MEAL_BAG_INGREDIENT_ASSIGNMENTS_SUCCESS = apiSuccessConstant(
  MEAL_BAG_INGREDIENT_ASSIGNMENTS
);
export const MEAL_BAG_INGREDIENT_ASSIGNMENTS_ERROR = apiErrorConstant(
  MEAL_BAG_INGREDIENT_ASSIGNMENTS
);

export const INGREDIENT_INVENTORY_POST = 'INGREDIENT_INVENTORY_POST';
export const INGREDIENT_INVENTORY_POST_REQUEST = apiRequestConstant(
  INGREDIENT_INVENTORY_POST
);
export const INGREDIENT_INVENTORY_POST_SUCCESS = apiSuccessConstant(
  INGREDIENT_INVENTORY_POST
);
export const INGREDIENT_INVENTORY_POST_ERROR = apiErrorConstant(
  INGREDIENT_INVENTORY_POST
);

export const LABEL_INVENTORY_POST = 'LABEL_INVENTORY_POST';
export const LABEL_INVENTORY_POST_REQUEST = apiRequestConstant(
  LABEL_INVENTORY_POST
);
export const LABEL_INVENTORY_POST_SUCCESS = apiSuccessConstant(
  LABEL_INVENTORY_POST
);
export const LABEL_INVENTORY_POST_ERROR = apiErrorConstant(
  LABEL_INVENTORY_POST
);

export const LABEL_INVENTORY_PRINT = 'LABEL_INVENTORY_PRINT';
export const LABEL_INVENTORY_PRINT_REQUEST = apiRequestConstant(
  LABEL_INVENTORY_PRINT
);
export const LABEL_INVENTORY_PRINT_SUCCESS = apiSuccessConstant(
  LABEL_INVENTORY_PRINT
);
export const LABEL_INVENTORY_PRINT_ERROR = apiErrorConstant(
  LABEL_INVENTORY_PRINT
);

export const INGREDIENT_INVENTORY_PRINT = 'INGREDIENT_INVENTORY_PRINT';
export const INGREDIENT_INVENTORY_PRINT_REQUEST = apiRequestConstant(
  INGREDIENT_INVENTORY_PRINT
);
export const INGREDIENT_INVENTORY_PRINT_SUCCESS = apiSuccessConstant(
  INGREDIENT_INVENTORY_PRINT
);
export const INGREDIENT_INVENTORY_PRINT_ERROR = apiErrorConstant(
  INGREDIENT_INVENTORY_PRINT
);

export const PRINTERS = 'PRINTERS';
export const PRINTERS_REQUEST = apiRequestConstant(PRINTERS);
export const PRINTERS_SUCCESS = apiSuccessConstant(PRINTERS);
export const PRINTERS_ERROR = apiErrorConstant(PRINTERS);

export const SESSION_CREATE = 'SESSION_CREATE';
export const SESSION_CREATE_ERROR = apiErrorConstant(SESSION_CREATE);
export const SESSION_CREATE_SUCCESS = apiSuccessConstant(SESSION_CREATE);
export const SESSION_CREATE_REQUEST = apiRequestConstant(SESSION_CREATE);
export const SESSION_CLEAR = 'SESSION_CLEAR';
export const SESSION_CLEAR_SUCCESS = apiSuccessConstant(SESSION_CLEAR);

export const SESSION = 'SESSION';
export const SESSION_REQUEST = apiRequestConstant(SESSION);
export const SESSION_SUCCESS = apiSuccessConstant(SESSION);
export const SESSION_ERROR = apiErrorConstant(SESSION);
export const SESSION_UPDATED = 'SESSION_UPDATED';

export const POD_LOCATIONS = 'POD_LOCATIONS';
export const POD_LOCATIONS_REQUEST = apiRequestConstant(POD_LOCATIONS);
export const POD_LOCATIONS_SUCCESS = apiSuccessConstant(POD_LOCATIONS);

export const GET_PRODUCTION_SUBMISSIONS = 'GET_PRODUCTION_SUBMISSIONS';
export const GET_PRODUCTION_SUBMISSIONS_REQUEST = apiRequestConstant(
  GET_PRODUCTION_SUBMISSIONS
);
export const GET_PRODUCTION_SUBMISSIONS_SUCCESS = apiSuccessConstant(
  GET_PRODUCTION_SUBMISSIONS
);

export const BOXING_INVENTORY = 'BOXING_INVENTORY';
export const BOXING_INVENTORY_REQUEST = apiRequestConstant(BOXING_INVENTORY);
export const BOXING_INVENTORY_SUCCESS = apiSuccessConstant(BOXING_INVENTORY);

export const MEALS_AND_INGREDIENTS = 'MEALS_AND_INGREDIENTS';
export const PRODUCTION_PROGRESS_REQUEST = apiRequestConstant(
  MEALS_AND_INGREDIENTS
);
export const PRODUCTION_PROGRESS_SUCCESS = apiSuccessConstant(
  MEALS_AND_INGREDIENTS
);
export const ALL_PANELS_EXPANDED = 'ALL_PANELS_EXPANDED';
export const ALL_PANELS_COLLAPSED = 'ALL_PANELS_COLLAPSED';
export const PANEL_TOGGLED = 'PANEL_TOGGLED';

export const QA_PORTION_MEALS = 'QA_PORTION_MEALS';
export const QA_PORTION_MEALS_REQUEST = apiRequestConstant(QA_PORTION_MEALS);
export const QA_PORTION_MEALS_SUCCESS = apiSuccessConstant(QA_PORTION_MEALS);
export const QA_PORTION_MEALS_UPDATED = 'QA_PORTION_MEALS_UPDATED';

export const QA_PORTION_CHECKS_POST = 'QA_PORTION_CHECKS_POST';
export const QA_PORTION_CHECKS_POST_REQUEST = apiRequestConstant(
  QA_PORTION_CHECKS_POST
);
export const QA_PORTION_CHECKS_POST_SUCCESS = apiSuccessConstant(
  QA_PORTION_CHECKS_POST
);
export const QA_PORTION_CHECKS_POST_ERROR = apiErrorConstant(
  QA_PORTION_CHECKS_POST
);
export const QA_PORTION_CHECKS_UPDATED = 'QA_PORTION_CHECKS_UPDATED';
export const QA_PORTION_CHECKS_RESET = 'QA_PORTION_CHECKS_RESET';
export const QA_PORTION_CHECKS_COMPLETE = 'QA_PORTION_CHECKS_COMPLETE';

export const QA_MEAL_BAG_CHECKS_POST = 'QA_MEAL_BAG_CHECKS_POST';
export const QA_MEAL_BAG_CHECKS_POST_REQUEST = apiRequestConstant(
  QA_MEAL_BAG_CHECKS_POST
);
export const QA_MEAL_BAG_CHECKS_POST_SUCCESS = apiSuccessConstant(
  QA_MEAL_BAG_CHECKS_POST
);
export const QA_MEAL_BAG_CHECKS_POST_ERROR = apiErrorConstant(
  QA_PORTION_CHECKS_POST
);
export const QA_MEAL_BAG_CHECKS_UPDATED = 'QA_MEAL_BAG_CHECKS_UPDATED';
export const QA_MEAL_BAG_CHECKS_RESET = 'QA_MEAL_BAG_CHECKS_RESET';

export const QA_MEAL_BAG_PRELIMINARY_CHECK_POST =
  'QA_MEAL_BAG_PRELIMINARY_CHECK_POST';
export const QA_MEAL_BAG_PRELIMINARY_CHECK_POST_REQUEST = apiRequestConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST
);
export const QA_MEAL_BAG_PRELIMINARY_CHECK_POST_SUCCESS = apiSuccessConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST
);
export const QA_MEAL_BAG_PRELIMINARY_CHECK_POST_ERROR = apiErrorConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_POST
);

export const QA_MEAL_BAG_PRELIMINARY_CHECK_PUT =
  'QA_MEAL_BAG_PRELIMINARY_CHECK_PUT';
export const QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_REQUEST = apiRequestConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT
);
export const QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_SUCCESS = apiSuccessConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT
);
export const QA_MEAL_BAG_PRELIMINARY_CHECK_PUT_ERROR = apiErrorConstant(
  QA_MEAL_BAG_PRELIMINARY_CHECK_PUT
);

export const QA_PRELIMINARY_CHECK_POST = 'QA_PRELIMINARY_CHECK_POST';
export const QA_PRELIMINARY_CHECK_POST_REQUEST = apiRequestConstant(
  QA_PRELIMINARY_CHECK_POST
);
export const QA_PRELIMINARY_CHECK_POST_SUCCESS = apiSuccessConstant(
  QA_PRELIMINARY_CHECK_POST
);
export const QA_PRELIMINARY_CHECK_POST_ERROR = apiErrorConstant(
  QA_PRELIMINARY_CHECK_POST
);

export const RTH_BATCH_PROGRESSES = 'RTH_BATCH_PROGRESSES';
export const RTH_BATCH_PROGRESSES_REQUEST = apiRequestConstant(
  RTH_BATCH_PROGRESSES
);
export const RTH_BATCH_PROGRESSES_SUCCESS = apiSuccessConstant(
  RTH_BATCH_PROGRESSES
);
export const RTH_BATCH_PROGRESSES_ERROR = apiErrorConstant(
  RTH_BATCH_PROGRESSES
);

export const RTH_INGREDIENTS = 'RTH_INGREDIENTS';
export const RTH_INGREDIENTS_REQUEST = apiRequestConstant(RTH_INGREDIENTS);
export const RTH_INGREDIENTS_SUCCESS = apiSuccessConstant(RTH_INGREDIENTS);
export const RTH_INGREDIENTS_ERROR = apiErrorConstant(RTH_INGREDIENTS);

export const INGREDIENTS = 'INGREDIENTS';
export const INGREDIENTS_REQUEST = apiRequestConstant(INGREDIENTS);
export const INGREDIENTS_SUCCESS = apiSuccessConstant(INGREDIENTS);
export const INGREDIENTS_ERROR = apiErrorConstant(INGREDIENTS);

export const PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT =
  'PRODUCTION_PROGRESS_FOR_INGREDIENT';
export const PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_REQUEST = apiRequestConstant(
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT
);
export const PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_SUCCESS = apiSuccessConstant(
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT
);
export const PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT_ERROR = apiErrorConstant(
  PRODUCTION_PROGRESS_FOR_CUSTOMIZE_IT_INGREDIENT
);

export const ITEM_MOVE_REQUESTS_GET = 'ITEM_MOVE_REQUESTS_GET';
export const ITEM_MOVE_REQUESTS_GET_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUESTS_GET
);
export const ITEM_MOVE_REQUESTS_GET_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUESTS_GET
);
export const ITEM_MOVE_REQUESTS_GET_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUESTS_GET
);

export const ITEM_MOVE_REQUEST_POST = 'ITEM_MOVE_REQUEST_POST';
export const ITEM_MOVE_REQUEST_POST_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUEST_POST
);
export const ITEM_MOVE_REQUEST_POST_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUEST_POST
);
export const ITEM_MOVE_REQUEST_POST_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUEST_POST
);
export const ITEM_MOVE_REQUEST_UPDATE = 'ITEM_MOVE_REQUEST_UPDATE';

export const UPDATE_MOVE_REQUEST_FILTERS = 'UPDATE_MOVE_REQUEST_FILTERS';

export const ITEM_MOVE_REQUEST_PUT = 'ITEM_MOVE_REQUEST_PUT';
export const ITEM_MOVE_REQUEST_PUT_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUEST_PUT
);
export const ITEM_MOVE_REQUEST_PUT_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUEST_PUT
);
export const ITEM_MOVE_REQUEST_PUT_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUEST_PUT
);

export const ITEM_MOVE_REQUESTS_QUERY = 'WAREHOUSE_REQUESTS_QUERY';
export const ITEM_MOVE_REQUESTS_QUERY_GET_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUESTS_QUERY
);
export const ITEM_MOVE_REQUESTS_QUERY_GET_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUESTS_QUERY
);
export const ITEM_MOVE_REQUESTS_QUERY_GET_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUESTS_QUERY
);
export const ITEM_MOVE_REQUESTS_CLEAR_SEARCH =
  'ITEM_MOVE_REQUESTS_CLEAR_SEARCH';

export const HISTORICAL_ITEM_MOVE_REQUESTS_GET =
  'HISTORICAL_ITEM_MOVE_REQUESTS_GET';
export const HISTORICAL_ITEM_MOVE_REQUESTS_GET_REQUEST = apiRequestConstant(
  HISTORICAL_ITEM_MOVE_REQUESTS_GET
);
export const HISTORICAL_ITEM_MOVE_REQUESTS_GET_SUCCESS = apiSuccessConstant(
  HISTORICAL_ITEM_MOVE_REQUESTS_GET
);
export const HISTORICAL_ITEM_MOVE_REQUESTS_GET_ERROR = apiErrorConstant(
  HISTORICAL_ITEM_MOVE_REQUESTS_GET
);

export const ITEM_MOVE_REQUEST_CASE_CONVERSION_GET =
  'ITEM_MOVE_REQUEST_CASE_CONVERSION_GET';
export const ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET
);
export const ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET
);
export const ITEM_MOVE_REQUEST_CASE_CONVERSION_GET_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUEST_CASE_CONVERSION_GET
);

export const ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET =
  'ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET';
export const ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_REQUEST = apiRequestConstant(
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET
);
export const ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_SUCCESS = apiSuccessConstant(
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET
);
export const ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET_ERROR = apiErrorConstant(
  ITEM_MOVE_REQUEST_SUGGESTED_PALLET_GET
);

export const ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET =
  'ITEM_MOVE_REQUEST_CLEAR_SUGGESTED_PALLET';

export const PRINT_CHILD_LICENSE_PLATE_POST = 'PRINT_CHILD_LICENSE_PLATE_POST';
export const PRINT_CHILD_LICENSE_PLATE_POST_REQUEST = apiRequestConstant(
  PRINT_CHILD_LICENSE_PLATE_POST
);
export const PRINT_CHILD_LICENSE_PLATE_POST_SUCCESS = apiSuccessConstant(
  PRINT_CHILD_LICENSE_PLATE_POST
);
export const PRINT_CHILD_LICENSE_PLATE_POST_ERROR = apiErrorConstant(
  PRINT_CHILD_LICENSE_PLATE_POST
);

export const GUIDED_PICKING_OVERRIDE_POST = 'GUIDED_PICKING_OVERRIDE_POST';
export const GUIDED_PICKING_OVERRIDE_POST_REQUEST = apiRequestConstant(
  GUIDED_PICKING_OVERRIDE_POST
);
export const GUIDED_PICKING_OVERRIDE_POST_SUCCESS = apiSuccessConstant(
  GUIDED_PICKING_OVERRIDE_POST
);
export const GUIDED_PICKING_OVERRIDE_POST_ERROR = apiErrorConstant(
  GUIDED_PICKING_OVERRIDE_POST
);

export const VIEW_BOX_DETAILS = 'VIEW_BOX_DETAILS';
export const VIEW_BOX_DETAILS_REQUEST = apiRequestConstant(VIEW_BOX_DETAILS);
export const VIEW_BOX_DETAILS_SUCCESS = apiSuccessConstant(VIEW_BOX_DETAILS);
export const VIEW_BOX_DETAILS_ERROR = apiErrorConstant(VIEW_BOX_DETAILS);
export const CLEAR_SHIPMENT_DETAILS = 'CLEAR_SHIPMENT_DETAILS';

export const PRINT_NEW_LABEL = 'PRINT_NEW_LABEL';
export const PRINT_NEW_LABEL_REQUEST = apiRequestConstant(PRINT_NEW_LABEL);
export const PRINT_NEW_LABEL_SUCCESS = apiSuccessConstant(PRINT_NEW_LABEL);
export const PRINT_NEW_LABEL_ERROR = apiErrorConstant(PRINT_NEW_LABEL);
export const CLEAR_SHIPPING_OPTION_DETAILS = 'CLEAR_SHIPPING_OPTION_DETAILS';

export const CREATE_REPLACEMENT_LABEL = 'CREATE_REPLACEMENT_LABEL';
export const CREATE_REPLACEMENT_LABEL_REQUEST = apiRequestConstant(
  CREATE_REPLACEMENT_LABEL
);
export const CREATE_REPLACEMENT_LABEL_SUCCESS = apiSuccessConstant(
  CREATE_REPLACEMENT_LABEL
);
export const CREATE_REPLACEMENT_LABEL_ERROR = apiErrorConstant(
  CREATE_REPLACEMENT_LABEL
);

export const TRUCK_POST = 'TRUCK_POST';
export const TRUCK_POST_REQUEST = apiRequestConstant(TRUCK_POST);
export const TRUCK_POST_SUCCESS = apiSuccessConstant(TRUCK_POST);
export const TRUCK_POST_ERROR = apiErrorConstant(TRUCK_POST);

export const TRUCK_PUT = 'TRUCK_PUT';
export const TRUCK_PUT_REQUEST = apiRequestConstant(TRUCK_PUT);
export const TRUCK_PUT_SUCCESS = apiSuccessConstant(TRUCK_PUT);
export const TRUCK_PUT_ERROR = apiErrorConstant(TRUCK_PUT);

export const TRUCKS_GET = 'TRUCKS_GET';
export const TRUCKS_GET_REQUEST = apiRequestConstant(TRUCKS_GET);
export const TRUCKS_GET_SUCCESS = apiSuccessConstant(TRUCKS_GET);
export const TRUCKS_GET_ERROR = apiErrorConstant(TRUCKS_GET);

export const UPDATE_TRUCKS = 'UPDATE_TRUCKS';
export const TRUCK_DRAWER_TOGGLE = 'TRUCK_DRAWER_TOGGLE';
export const SELECT_TRUCK = 'SELECT_TRUCK';
export const ADD_TRUCK = 'ADD_TRUCK';
export const PRESELECT_ORDER_FOR_TRUCK = 'PRESELECT_ORDER_FOR_TRUCK';
export const CLEAR_PRESELECTED_TRUCK_ORDER = 'CLEAR_PRESELECTED_TRUCK_ORDER';

export const NETSUITE_ORDER_CLEAR_SEARCH = 'NETSUITE_ORDER_CLEAR_SEARCH';
export const NETSUITE_ORDER_STORE_SEARCH = 'NETSUITE_ORDER_STORE_SEARCH';

export const NETSUITE_ORDER_QUERY = 'NETSUITE_ORDER_QUERY';
export const NETSUITE_ORDER_QUERY_GET_REQUEST = apiRequestConstant(
  NETSUITE_ORDER_QUERY
);
export const NETSUITE_ORDER_QUERY_GET_SUCCESS = apiSuccessConstant(
  NETSUITE_ORDER_QUERY
);
export const NETSUITE_ORDER_QUERY_GET_ERROR = apiErrorConstant(
  NETSUITE_ORDER_QUERY
);

export const POD_STATUSES = 'POD_STATUSES';
export const POD_STATUSES_REQUEST = apiRequestConstant(POD_STATUSES);
export const POD_STATUSES_SUCCESS = apiSuccessConstant(POD_STATUSES);
export const POD_STATUSES_UPDATED = 'POD_STATUSES_UPDATED';

export const MENUS = 'MENUS';
export const MENUS_REQUEST = apiRequestConstant(MENUS);
export const MENUS_SUCCESS = apiSuccessConstant(MENUS);
export const MENUS_UPDATED = 'MENUS_UPDATED';
export const MENU_SELECTED = 'MENU_SELECTED';

export const LOAD_BALANCER_MENUS = 'LOAD_BALANCER_MENUS';
export const LOAD_BALANCER_MENUS_SUCCESS = apiSuccessConstant(
  LOAD_BALANCER_MENUS
);
export const LOAD_BALANCER_POST = 'LOAD_BALANCER_POST';
export const LOAD_BALANCER_POST_SUCCESS = apiSuccessConstant(
  LOAD_BALANCER_POST
);
export const LOAD_BALANCER_LAST_RUN = 'LOAD_BALANCER_LAST_RUN';
export const LOAD_BALANCER_LAST_RUN_SUCCESS = apiSuccessConstant(
  LOAD_BALANCER_LAST_RUN
);
export const LOAD_BALANCER_DELETE = 'LOAD_BALANCER_DELETE';
export const LOAD_BALANCER_DELETE_SUCCESS = apiSuccessConstant(
  LOAD_BALANCER_DELETE
);

export const MENU_SELECTIONS = 'MENU_SELECTIONS';
export const MENU_SELECTIONS_REQUEST = apiRequestConstant(MENU_SELECTIONS);
export const MENU_SELECTIONS_SUCCESS = apiSuccessConstant(MENU_SELECTIONS);
export const MENU_SELECTIONS_ERROR = apiErrorConstant(MENU_SELECTIONS);

export const MEALS_FOR_INGREDIENT_ASSIGNMENT =
  'MEALS_FOR_INGREDIENT_ASSIGNMENT';
export const MEALS_FOR_INGREDIENT_ASSIGNMENT_REQUEST = apiRequestConstant(
  MEALS_FOR_INGREDIENT_ASSIGNMENT
);
export const MEALS_FOR_INGREDIENT_ASSIGNMENT_SUCCESS = apiSuccessConstant(
  MEALS_FOR_INGREDIENT_ASSIGNMENT
);
export const MEALS_FOR_INGREDIENT_ASSIGNMENT_ERROR = apiErrorConstant(
  MEALS_FOR_INGREDIENT_ASSIGNMENT
);
export const CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT =
  'CLEAR_MEALS_FOR_INGREDIENT_ASSIGNMENT';
export const CLEAR_INGREDIENT_ASSIGNMENT = 'CLEAR_INGREDIENT_ASSIGNMENT';

export const PROGRESS_OVERVIEW = 'PROGRESS_OVERVIEW';
export const PROGRESS_OVERVIEW_REQUEST = apiRequestConstant(PROGRESS_OVERVIEW);
export const PROGRESS_OVERVIEW_SUCCESS = apiSuccessConstant(PROGRESS_OVERVIEW);

export const POD_STATUS = 'POD_STATUS';
export const POD_STATUS_REQUEST = apiRequestConstant(POD_STATUS);
export const POD_STATUS_SUCCESS = apiSuccessConstant(POD_STATUS);
export const POD_STATUS_ERROR = apiErrorConstant(POD_STATUS);

export const SELECTED_POD_UPDATED = 'SELECTED_POD_UPDATED';

export const SELECTED_SHIP_DATE_UPDATED = 'SELECTED_SHIP_DATE_UPDATED';

export const SELECTED_FACILITY_UPDATED = 'SELECTED_FACILITY_UPDATED';

export const TOP_BAR_RESET = 'TOP_BAR_RESET';
export const TOP_BAR_UPDATE_CONTENT = 'TOP_BAR_UPDATE_CONTENT';

export const LAST_SUCCESSFUL_FETCH_AT_UPDATED =
  'LAST_SUCCESSFUL_FETCH_AT_UPDATED';

export const WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER =
  'WAREHOUSE_AUDIT_TOGGLE_EXPAND_ORDER';
export const WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM =
  'WAREHOUSE_AUDIT_TOGGLE_EXPAND_ITEM';
export const WAREHOUSE_AUDIT_EXPAND_ALL = 'WAREHOUSE_AUDIT_EXPAND_ALL';
export const WAREHOUSE_AUDIT_COLLAPSE_ALL = 'WAREHOUSE_AUDIT_COLLAPSE_ALL';

export const WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS =
  'WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS';
export const WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_REQUEST = apiRequestConstant(
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS
);
export const WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_SUCCESS = apiSuccessConstant(
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS
);
export const WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS_ERROR = apiErrorConstant(
  WAREHOUSE_REQUESTS_INGREDIENT_ASSIGNMENTS
);

export const WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS =
  'WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS';
export const WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_REQUEST = apiRequestConstant(
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS
);
export const WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_SUCCESS = apiSuccessConstant(
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS
);
export const WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS_ERROR = apiErrorConstant(
  WAREHOUSE_REQUESTS_PICKABLE_INGREDIENT_ASSIGNMENTS
);

export const INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS =
  'INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS';
export const INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS_REQUEST = apiRequestConstant(
  INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS
);
export const INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS_SUCCESS = apiSuccessConstant(
  INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS
);
export const INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS_ERROR = apiErrorConstant(
  INVENTORY_CHECK_INGREDIENT_ASSIGNMENTS
);

export const WAREHOUSE_REQUESTS_PACKAGING_ITEMS =
  'WAREHOUSE_REQUESTS_PACKAGING_ITEMS';
export const WAREHOUSE_REQUESTS_PACKAGING_ITEMS_REQUEST = apiRequestConstant(
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS
);
export const WAREHOUSE_REQUESTS_PACKAGING_ITEMS_SUCCESS = apiSuccessConstant(
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS
);
export const WAREHOUSE_REQUESTS_PACKAGING_ITEMS_ERROR = apiErrorConstant(
  WAREHOUSE_REQUESTS_PACKAGING_ITEMS
);

export const PRODUCTION_PLANS_POST = 'PRODUCTION_PLANS_POST';
export const PRODUCTION_PLANS_POST_REQUEST = apiRequestConstant(
  PRODUCTION_PLANS_POST
);
export const PRODUCTION_PLANS_POST_SUCCESS = apiSuccessConstant(
  PRODUCTION_PLANS_POST
);
export const PRODUCTION_PLANS_POST_ERROR = apiErrorConstant(
  PRODUCTION_PLANS_POST
);

export const PRODUCTION_PLANS = 'PRODUCTION_PLANS';
export const PRODUCTION_PLANS_REQUEST = apiRequestConstant(PRODUCTION_PLANS);
export const PRODUCTION_PLANS_SUCCESS = apiSuccessConstant(PRODUCTION_PLANS);
export const PRODUCTION_PLANS_ERROR = apiErrorConstant(PRODUCTION_PLANS);

export const PRODUCTION_PLANS_LEAD_POST = 'PRODUCTION_PLANS_LEAD';
export const PRODUCTION_PLANS_LEAD_POST_REQUEST = apiRequestConstant(
  PRODUCTION_PLANS_LEAD_POST
);
export const PRODUCTION_PLANS_LEAD_POST_SUCCESS = apiSuccessConstant(
  PRODUCTION_PLANS_LEAD_POST
);
export const PRODUCTION_PLANS_LEAD_POST_ERROR = apiErrorConstant(
  PRODUCTION_PLANS_LEAD_POST
);

export const SHIFTS = 'SHIFTS';
export const SHIFTS_REQUEST = apiRequestConstant(SHIFTS);
export const SHIFTS_SUCCESS = apiSuccessConstant(SHIFTS);
export const SHIFTS_ERROR = apiErrorConstant(SHIFTS);

export const COUNT_LIST_CREATE = 'COUNT_LIST_CREATE';
export const COUNT_LIST_CREATE_REQUEST = apiRequestConstant(COUNT_LIST_CREATE);
export const COUNT_LIST_CREATE_SUCCESS = apiSuccessConstant(COUNT_LIST_CREATE);
export const COUNT_LIST_CREATE_ERROR = apiErrorConstant(COUNT_LIST_CREATE);

export const COUNT_LIST_UPDATE = 'COUNT_LIST_UPDATE';
export const COUNT_LIST_UPDATE_REQUEST = apiRequestConstant(COUNT_LIST_UPDATE);
export const COUNT_LIST_UPDATE_REQUEST_ERROR = apiErrorConstant(
  COUNT_LIST_UPDATE_REQUEST
);
export const COUNT_LIST_UPDATE_REQUEST_SUCCESS = apiSuccessConstant(
  COUNT_LIST_UPDATE_REQUEST
);
export const CLEAR_CYCLE_COUNT_INFO = 'CLEAR_CYCLE_COUNT_INFO';

export const UPDATE_PALLET_LOCATION = 'UPDATE_PALLET_LOCATION';
export const UPDATE_PALLET_LOCATION_REQUEST = apiRequestConstant(
  UPDATE_PALLET_LOCATION
);
export const UPDATE_PALLET_LOCATION_ERROR = apiErrorConstant(
  UPDATE_PALLET_LOCATION
);
export const UPDATE_PALLET_LOCATION_SUCCESS = apiSuccessConstant(
  UPDATE_PALLET_LOCATION
);

export const REFRESH_COUNT_LIST = 'REFRESH_COUNT_LIST';
export const REFRESH_COUNT_LIST_REQUEST = apiRequestConstant(
  REFRESH_COUNT_LIST
);
export const REFRESH_COUNT_LIST_REQUEST_ERROR = apiErrorConstant(
  REFRESH_COUNT_LIST
);
export const REFRESH_COUNT_LIST_REQUEST_SUCCESS = apiSuccessConstant(
  REFRESH_COUNT_LIST
);

export const COUNT_LIST_DELETE = 'COUNT_LIST_DELETE';
export const COUNT_LIST_DELETE_REQUEST = apiRequestConstant(COUNT_LIST_DELETE);
export const COUNT_LIST_DELETE_REQUEST_ERROR = apiErrorConstant(
  COUNT_LIST_DELETE_REQUEST
);
export const COUNT_LIST_DELETE_REQUEST_SUCCESS = apiSuccessConstant(
  COUNT_LIST_DELETE_REQUEST
);

export const GET_ACTIVITY_INGREDIENTS = 'GET_ACTIVITY_INGREDIENTS';
export const GET_ACTIVITY_INGREDIENTS_REQUEST = apiRequestConstant(
  GET_ACTIVITY_INGREDIENTS
);
export const GET_ACTIVITY_INGREDIENTS_SUCCESS = apiSuccessConstant(
  GET_ACTIVITY_INGREDIENTS
);
export const GET_ACTIVITY_INGREDIENTS_ERROR = apiErrorConstant(
  GET_ACTIVITY_INGREDIENTS
);

export const GET_ACTIVITY_INGREDIENT_PROGRESSES =
  'GET_ACTIVITY_INGREDIENT_PROGRESSES';
export const GET_ACTIVITY_INGREDIENT_PROGRESSES_REQUEST = apiRequestConstant(
  GET_ACTIVITY_INGREDIENT_PROGRESSES
);
export const GET_ACTIVITY_INGREDIENT_PROGRESSES_SUCCESS = apiSuccessConstant(
  GET_ACTIVITY_INGREDIENT_PROGRESSES
);
export const GET_ACTIVITY_INGREDIENT_PROGRESSES_ERROR = apiErrorConstant(
  GET_ACTIVITY_INGREDIENT_PROGRESSES
);

export const GET_MEAL_PROGRESSES = 'GET_MEAL_PROGRESSES';
export const GET_MEAL_PROGRESSES_REQUEST = apiRequestConstant(
  GET_MEAL_PROGRESSES
);
export const GET_MEAL_PROGRESSES_SUCCESS = apiSuccessConstant(
  GET_MEAL_PROGRESSES
);
export const GET_MEAL_PROGRESSES_ERROR = apiErrorConstant(GET_MEAL_PROGRESSES);

export const SUBMIT_MEAL_PROGRESS = 'SUBMIT_MEAL_PROGRESS';
export const SUBMIT_MEAL_PROGRESS_REQUEST = apiRequestConstant(
  SUBMIT_MEAL_PROGRESS
);
export const SUBMIT_MEAL_PROGRESS_SUCCESS = apiSuccessConstant(
  SUBMIT_MEAL_PROGRESS
);
export const SUBMIT_MEAL_PROGRESS_ERROR = apiErrorConstant(
  SUBMIT_MEAL_PROGRESS
);

export const SUBMIT_INGREDIENT_PROGRESS = 'SUBMIT_INGREDIENT_PROGRESS';
export const SUBMIT_INGREDIENT_PROGRESS_REQUEST = apiRequestConstant(
  SUBMIT_INGREDIENT_PROGRESS
);
export const SUBMIT_INGREDIENT_PROGRESS_SUCCESS = apiSuccessConstant(
  SUBMIT_INGREDIENT_PROGRESS
);
export const SUBMIT_INGREDIENT_PROGRESS_ERROR = apiErrorConstant(
  SUBMIT_INGREDIENT_PROGRESS
);

export const UPDATE_INGREDIENT_PROGRESS = 'UPDATE_INGREDIENT_PROGRESS';
export const UPDATE_INGREDIENT_PROGRESS_REQUEST = apiRequestConstant(
  UPDATE_INGREDIENT_PROGRESS
);
export const UPDATE_INGREDIENT_PROGRESS_SUCCESS = apiSuccessConstant(
  UPDATE_INGREDIENT_PROGRESS
);
export const UPDATE_INGREDIENT_PROGRESS_ERROR = apiErrorConstant(
  UPDATE_INGREDIENT_PROGRESS
);

export const PRINT_RTH_LABELS = 'PRINT_RTH_LABELS';
export const PRINT_RTH_LABELS_REQUEST = apiRequestConstant(PRINT_RTH_LABELS);
export const PRINT_RTH_LABELS_SUCCESS = apiSuccessConstant(PRINT_RTH_LABELS);
export const PRINT_RTH_LABELS_ERROR = apiErrorConstant(PRINT_RTH_LABELS);

export const GET_SLACKING_TRACKER_PROGRESS = 'GET_SLACKING_TRACKER_PROGRESS';
export const GET_SLACKING_TRACKER_PROGRESS_REQUEST = apiRequestConstant(
  GET_SLACKING_TRACKER_PROGRESS
);
export const GET_SLACKING_TRACKER_PROGRESS_SUCCESS = apiSuccessConstant(
  GET_SLACKING_TRACKER_PROGRESS
);
export const GET_SLACKING_TRACKER_PROGRESS_ERROR = apiErrorConstant(
  GET_SLACKING_TRACKER_PROGRESS
);

export const GET_PORTIONING_TRACKER_PROGRESS =
  'GET_PORTIONING_TRACKER_PROGRESS';
export const GET_PORTIONING_TRACKER_PROGRESS_REQUEST = apiRequestConstant(
  GET_PORTIONING_TRACKER_PROGRESS
);
export const GET_PORTIONING_TRACKER_PROGRESS_SUCCESS = apiSuccessConstant(
  GET_PORTIONING_TRACKER_PROGRESS
);
export const GET_PORTIONING_TRACKER_PROGRESS_ERROR = apiErrorConstant(
  GET_PORTIONING_TRACKER_PROGRESS
);

export const GET_INGREDIENTS_TRACKER_PROGRESS =
  'GET_INGREDIENTS_TRACKER_PROGRESS';
export const GET_INGREDIENTS_TRACKER_PROGRESS_REQUEST = apiRequestConstant(
  GET_INGREDIENTS_TRACKER_PROGRESS
);
export const GET_INGREDIENTS_TRACKER_PROGRESS_SUCCESS = apiSuccessConstant(
  GET_INGREDIENTS_TRACKER_PROGRESS
);
export const GET_INGREDIENTS_TRACKER_PROGRESS_ERROR = apiErrorConstant(
  GET_INGREDIENTS_TRACKER_PROGRESS
);

export const GET_MEALS_TRACKER_PROGRESS = 'GET_MEALS_TRACKER_PROGRESS';
export const GET_MEALS_TRACKER_PROGRESS_REQUEST = apiRequestConstant(
  GET_MEALS_TRACKER_PROGRESS
);
export const GET_MEALS_TRACKER_PROGRESS_SUCCESS = apiSuccessConstant(
  GET_MEALS_TRACKER_PROGRESS
);
export const GET_MEALS_TRACKER_PROGRESS_ERROR = apiErrorConstant(
  GET_MEALS_TRACKER_PROGRESS
);

export const GET_ASSEMBLY_TRACKER_PROGRESS = 'GET_ASSEMBLY_TRACKER_PROGRESS';
export const GET_ASSEMBLY_TRACKER_PROGRESS_REQUEST = apiRequestConstant(
  GET_ASSEMBLY_TRACKER_PROGRESS
);
export const GET_ASSEMBLY_TRACKER_PROGRESS_SUCCESS = apiSuccessConstant(
  GET_ASSEMBLY_TRACKER_PROGRESS
);

export const GET_ASSEMBLY_TRACKER_PROGRESS_ERROR = apiErrorConstant(
  GET_ASSEMBLY_TRACKER_PROGRESS
);
