import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Firework = () => {
  const classes = useStyles();

  return (
    <div className={classes.fireworkContainer}>
      <div className={classes.firework} />
      <div className={`${classes.firework} ${classes.firework2}`} />
      <div className={`${classes.firework} ${classes.firework3}`} />
      <div className={`${classes.firework} ${classes.firework4}`} />
      <div className={`${classes.firework} ${classes.firework5}`} />
    </div>
  );
};

export default Firework;
