import { MEDIA_QUERY_PHONE_SCREEN, MEDIA_QUERY_TABLET } from 'lib/constants';

export default {
  '@keyframes rayAnim': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes sunpulse': {
    from: {
      boxShadow: '0 0 100px #ff0, 0 0 100px #ff0',
    },
    to: {
      boxShadow: '0 0 25px #ff0, 0 0 75px #ff0',
    },
  },
  ray: {
    background:
      'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: '80% 80% 0 0',
    marginLeft: '10px',
    opacity: 0.1,
    position: 'absolute',
  },
  ray1: {
    height: '170px',
    left: '15px',
    top: '-175px',
    transform: 'rotate(180deg)',
    width: '30px',
  },
  ray10: {
    height: '190px',
    left: '-60px',
    top: '-185px',
    transform: 'rotate(150deg)',
    width: '23px',
  },
  ray2: {
    height: '100px',
    left: '75px',
    top: '-90px',
    transform: 'rotate(220deg)',
    width: '8px',
  },
  ray3: {
    height: '170px',
    left: '100px',
    top: '-80px',
    transform: 'rotate(250deg)',
    width: '50px',
  },
  ray4: {
    height: '120px',
    left: '100px',
    top: '30px',
    transform: 'rotate(305deg)',
    width: '14px',
  },
  ray5: {
    height: '140px',
    left: '40px',
    top: '60px',
    transform: 'rotate(-15deg)',
    width: '30px',
  },
  ray6: {
    height: '90px',
    left: '-40px',
    top: '60px',
    transform: 'rotate(30deg)',
    width: '50px',
  },
  ray7: {
    height: '180px',
    left: '-40px',
    top: '-35px',
    transform: 'rotate(70deg)',
    width: '10px',
  },
  ray8: {
    height: '120px',
    left: '-90px',
    top: '-45px',
    transform: 'rotate(100deg)',
    width: '30px',
  },
  ray9: {
    height: '80px',
    left: '-60px',
    top: '-65px',
    transform: 'rotate(120deg)',
    width: '10px',
  },
  rayBox: {
    animation: '$rayAnim 120s linear infinite',
    bottom: 0,
    height: '70px',
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '70px',
  },
  sun: {
    animation: '$sunpulse 2s alternate infinite',
    backgroundImage: 'radial-gradient(circle, #ffd200 95%, #f7971e)',
    borderRadius: '50%',
    boxShadow: '0px 0px 15px 3px yellow',
    height: '100px',
    left: '90vw',
    opacity: 1,
    position: 'absolute',
    top: '5vh',
    width: '100px',
    zIndex: '-2',
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    ray1: {
      left: '-5px',
      height: '136px',
      width: '24px',
    },
    ray10: {
      left: '-80px',
      height: '152px',
      width: '18.4px',
    },
    ray2: {
      left: '55px',
      height: '80px',
      width: '6.4px',
    },
    ray3: {
      left: '80px',
      height: '136px',
      width: '40px',
    },
    ray4: {
      left: '80px',
      height: '96px',
      width: '11.2px',
    },
    ray5: {
      left: '20px',
      height: '112px',
      width: '24px',
    },
    ray6: {
      left: '-60px',
      height: '72px',
      width: '40px',
    },
    ray7: {
      left: '-60px',
      height: '144px',
      width: '8px',
    },
    ray8: {
      left: '-110px',
      height: '96px',
      width: '24px',
    },
    ray9: {
      left: '-80px',
      height: '64px',
      width: '8px',
    },
    sun: {
      left: '70vw',
      height: '80px',
      width: '80px',
    },
  },

  [MEDIA_QUERY_TABLET]: {
    ray1: {
      left: '26.4px',
      height: '171.6px',
      top: '10px',
      width: '30.36px',
    },
    ray10: {
      left: '-70.4px',
      height: '193.6px',
      top: '10px',
      width: '22.23px',
    },
    ray2: {
      left: '72.6px',
      height: '96.8px',
      top: '10px',
      width: '8.6px',
    },
    ray3: {
      left: '105.6px',
      height: '171.6px',
      top: '10px',
      width: '51.04px',
    },
    ray4: {
      left: '105.6px',
      height: '127.6px',
      top: '10px',
      width: '14.16px',
    },
    ray5: {
      left: '26.4px',
      height: '139.7px',
      top: '10px',
      width: '30.36px',
    },
    ray6: {
      left: '-52.8px',
      height: '90.4px',
      top: '10px',
      width: '51.04px',
    },
    ray7: {
      left: '-52.8px',
      height: '180.4px',
      top: '10px',
      width: '10.12px',
    },
    ray8: {
      left: '-96.8px',
      height: '127.6px',
      top: '10px',
      width: '30.36px',
    },
    ray9: {
      left: '-70.4px',
      height: '80.7px',
      top: '10px',
      width: '10.12px',
    },
    sun: {
      height: '100.4px',
      left: '80.4vw',
      top: '10px',
      width: '100.4px',
    },
  },
};
