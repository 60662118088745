import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { MAP_MACHINES } from 'lib/constants';
import {
  submitMealProgress,
  updateMealProgress,
} from 'redux/productionProgress/rth/actions';
import { StatefulButton } from 'components/shared';

import SublotCard from '../../SublotCard';
import IngredientAssignmentList from '../../IngredientAssignmentList';
import styles from './styles';
import FormDrawer from '../formComponents/FormDrawer';
import { PrinterContext } from '../../PrinterProvider';

const useStyles = makeStyles(styles);

const AssemblyForm = ({ selectedProgress, open, toggleOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [numberOfMeals, setNumberOfMeals] = useState();
  const [machineNumber, setMachineNumber] = useState();
  const { submitMealProgressFetching, submitMealProgressFailed } = useSelector(
    (state) => state.rthProductionProgress
  );
  const { setPrinterDialogOpen, setPrintSubmission } = useContext(
    PrinterContext
  );
  const { menuId } = useParams();

  const buttonText = {
    DEFAULT: 'CONFIRM',
    SUCCESS: 'Success!',
    LOADING: 'SAVING...',
    ERROR: 'ERROR - Try Again',
  };

  const closeForm = () => {
    // reset form values since this component doesn't unmount on close
    setNumberOfMeals('');
    setMachineNumber('');
    toggleOpen(false);
  };

  const printLabels = (sublotId, submissionCount, isReprint = false) => {
    const printData = {
      progress: selectedProgress,
      submissionCount,
      sublotId,
      isReprint,
    };
    setPrintSubmission(printData);
    setPrinterDialogOpen(true);
  };

  const onSubmit = async () => {
    const data = {
      progressId: selectedProgress.id,
      menuId: menuId,
      count: numberOfMeals,
      machineryIdentifier: machineNumber,
    };
    const response = await dispatch(submitMealProgress(data));

    if (response.ok) {
      printLabels(response.activity.id, numberOfMeals);
    }
  };

  const onUpdate = async (data) => {
    data.progressId = selectedProgress.id;
    data.menuId = menuId;

    const response = await dispatch(updateMealProgress(data));
    response.ok && closeForm();

    return response;
  };

  return (
    <>
      <FormDrawer open={open} toggleOpen={closeForm} drawerWidth="100%">
        <div className={classes.assemblyDrawerContainer}>
          <div className={classes.assemblyDrawerHeader}>
            {selectedProgress.mealTitle}
          </div>
          <IngredientAssignmentList
            ingredientAssignments={selectedProgress.ingredientAssignments}
          />
          <div className={classes.formLabelText}>
            How many meals are you assembling?
          </div>
          <TextField
            id="number-of-meals"
            label="Number of Meals"
            type="number"
            onChange={(e) => setNumberOfMeals(e.target.value)}
          />
          <FormControl>
            <div className={classes.formLabelText}>
              Which Map machine will you be using?
            </div>
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              onChange={(e) => setMachineNumber(e.target.value)}
            >
              {MAP_MACHINES.map((n) => (
                <FormControlLabel
                  key={n}
                  value={n}
                  control={<Radio />}
                  label={n}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <StatefulButton
            classes={{ root: classes.formSubmitButton }}
            type="submit"
            disabled={!numberOfMeals || !machineNumber}
            loading={submitMealProgressFetching}
            failed={submitMealProgressFailed}
            buttonTextOptions={buttonText}
            onClick={onSubmit}
            successCallback={closeForm}
          />
          {!isEmpty(selectedProgress?.completedSublots) && (
            <>
              <hr className={classes.formDivider} />
              <div className={classes.sublotDetails}>
                <div className={classes.sublotHeader}>Completed Sublots</div>
                {selectedProgress.completedSublots.map((sublot) => {
                  return (
                    <div className={classes.sublot} key={sublot.id}>
                      <SublotCard
                        sublot={sublot}
                        onPrint={printLabels}
                        onUpdate={onUpdate}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </FormDrawer>
    </>
  );
};

AssemblyForm.propTypes = {
  selectedProgress: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};
export default AssemblyForm;
