import {
  MEDIA_QUERY_PHONE_SCREEN,
  MEDIA_QUERY_TABLET,
  MEDIA_QUERY_HUGE_SCREEN_OR_SMALLER,
} from 'lib/constants';

export default {
  beachChair: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/beach-chair.png") no-repeat',
    backgroundSize: 'contain',
    bottom: '100px',
    height: '380px',
    position: 'absolute',
    right: '-180px',
    width: '600px',
    zIndex: '-2',
  },
  sammySeal: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/sammy-seal.png") no-repeat',
    backgroundSize: 'contain',
    bottom: '95px',
    height: '280px',
    left: '0px',
    position: 'absolute',
    width: '400px',
    zIndex: '-2',
  },
  sunglasses: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/summer-sunglasses.png") no-repeat',
    backgroundSize: 'contain',
    bottom: '160px',
    height: '170px',
    position: 'absolute',
    right: '90px',
    transform: 'rotate(20deg)',
    width: '90px',
    zIndex: '0',
  },
  vanillaVince: {
    alignItems: 'center',
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/ops-apps-landing/vanilla-vince.png") no-repeat',
    backgroundSize: 'contain',
    bottom: '190px',
    display: 'flex',
    height: '110px',
    justifyContent: 'center',
    position: 'absolute',
    right: '-60px',
    transform: 'rotate(-10deg)',
    width: '300px',
    zIndex: 100,
  },
  willie: {
    background:
      'url("https://s3.us-west-2.amazonaws.com/asset.homechef.com/winter_rabbit.png") no-repeat right bottom',
    backgroundSize: 'contain',
    bottom: '180px',
    height: '180px',
    position: 'absolute',
    right: '60px',
    transform: 'rotate(35deg)',
    width: '185px',
    zIndex: 0,
  },

  [MEDIA_QUERY_PHONE_SCREEN]: {
    beachChair: {
      display: 'none',
    },
    sammySeal: {
      display: 'none',
    },
    sunglasses: {
      display: 'none',
    },
    vanillaVince: {
      display: 'none',
    },
    willie: {
      display: 'none',
    },
  },

  [MEDIA_QUERY_TABLET]: {
    beachChair: {
      bottom: '530px',
      height: '266px',
      right: '40px',
      width: '420px',
    },
    sammySeal: {
      display: 'none',
    },
    sunglasses: {
      bottom: '570px',
      height: '119px',
      right: '240px',
      width: '63px',
    },
    vanillaVince: {
      bottom: '530px',
      height: '119px',
      right: '260px',
      width: '63px',
    },
    willie: {
      bottom: '590px',
      height: '126px',
      right: '220px',
      width: '129px',
    },
  },

  [MEDIA_QUERY_HUGE_SCREEN_OR_SMALLER]: {
    sammySeal: {
      bottom: '350px',
      height: '150px',
      left: 'auto',
      right: '-250px',
      zIndex: '-3',
    },
  },
};
