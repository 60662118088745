import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Clouds = () => {
  const classes = useStyles();

  return (
    <div id="clouds" className={classes.clouds}>
      <div className={`${classes.cloud} ${classes.x1}`} />
      <div className={`${classes.cloud} ${classes.x2}`} />
      <div className={`${classes.cloud} ${classes.x3}`} />
      <div className={`${classes.cloud} ${classes.x4}`} />
      <div className={`${classes.cloud} ${classes.x5}`} />
    </div>
  );
};

export default Clouds;
