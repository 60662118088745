export default {
  '@keyframes firework': {
    '0%': {
      opacity: 1,
      transform: 'translate(var(--x), var(--initialY))',
      width: 'var(--initialSize)',
    },
    '50%': { opacity: 1, width: '0.7vmin' },
    '100%': { opacity: 0, width: 'var(--finalSize)' },
  },
  firework: {
    '--initialSize': '1vmin',
    '--finalSize': '60vmin',
    '--particleSize': '0.5vmin',
    '--color1': 'red',
    '--color2': 'orange',
    '--color3': 'yellow',
    '--color4': 'green',
    '--color5': 'blue',
    '--color6': 'purple',
    '--y': '-30vmin',
    '--x': '-50%',
    '--initialY': '60vmin',
    animation: '$firework 2s infinite',
    aspectRatio: '1',
    background: `
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
      radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
      radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
      radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
      radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
      radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
      radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'var(--initialSize) var(--initialSize)',
    content: '""',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, var(--y))',
    width: 'var(--initialSize)',
    zIndex: -1,
  },
  firework2: {
    '--color1': 'pink',
    '--color2': 'violet',
    '--color3': 'fuchsia',
    '--color4': 'orchid',
    '--color5': 'plum',
    '--color6': 'lavender',
    '--finalSize': '55vmin',
    '--x': '30vmin',
    animationDelay: '-0.25s',
    left: '30%',
    top: '60%',
  },
  firework3: {
    '--color1': 'cyan',
    '--color2': 'lightcyan',
    '--color3': 'lightblue',
    '--color4': 'PaleTurquoise',
    '--color5': 'SkyBlue',
    '--color6': 'lavender',
    '--finalSize': '50vmin',
    '--x': '-30vmin',
    '--y': '-50vmin',
    animationDelay: '-0.4s',
    left: '70%',
    top: '60%',
  },
  firework4: {
    '--color1': 'coral',
    '--color2': 'salmon',
    '--color3': 'tomato',
    '--color4': 'red',
    '--color5': 'darkorange',
    '--color6': 'gold',
    '--finalSize': '65vmin',
    '--x': '50vmin',
    animationDelay: '-0.3s',
    left: '20%',
    top: '50%',
  },
  firework5: {
    '--color1': 'lightcoral',
    '--color2': 'lightpink',
    '--color3': 'lightyellow',
    '--color4': 'peachpuff',
    '--color5': 'khaki',
    '--color6': 'lightgoldenrodyellow',
    '--finalSize': '60vmin',
    '--x': '-50vmin',
    animationDelay: '-0.45s',
    left: '80%',
    top: '40%',
  },
  fireworkContainer: {
    height: '100vh',
    left: 0,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: -2,
  },
};
